import './styles/main.scss'

import reportWebVitals from './reportWebVitals'
import { initSentryForProduction } from './sentry'
import { initHotJarForProduction } from './hotjar'
import { initClarity } from './clarity'

// initialize sentry
initSentryForProduction()
// initialize clarity
initClarity()
// initialize hotjar
initHotJarForProduction()
if (process.env.REACT_APP_TARGET_BUILD === 'vd') {
  import('vd-web')
} else if (process.env.REACT_APP_TARGET_BUILD === 'dashboard') {
  import('./dashboard/Dashboard')
} else {
  throw new Error(
    'wrong build target!, please check process.env.REACT_APP_TARGET_BUILD'
  )
}
// If you want to start measuring performance in your app, pass a function

// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
