import * as Sentry from '@sentry/react'

export function initSentryForProduction() {
  const isProd = process.env.REACT_APP_DEPLOYMENT === 'PRODUCTION'
  if (isProd) {
    const dsn = process.env.REACT_APP_SENTRY_DSN
    const base_url = process.env.REACT_APP_API_BASE_URL as string
    Sentry.init({
      dsn,
      integrations: [
        new Sentry.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: [
            'localhost',
            /^https:\/\/yourserver\.io\/api/,
            base_url,
          ],
        }),
        new Sentry.Replay(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
  }
}
