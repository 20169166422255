import Hotjar from '@hotjar/browser'

export function initHotJarForProduction() {
  const isProd = process.env.REACT_APP_DEPLOYMENT === 'PRODUCTION'
  if (isProd) {
    const siteId = 3822181
    const hotjarVersion = 6
    Hotjar.init(siteId, hotjarVersion)
  }
}
